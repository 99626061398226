<template>
    <div>
        <div class="mb-4">
            <h4 class="font-weight-bold">WAJIB RETRIBUSI DAERAH</h4>
            <em>
                Wajib Retribusi Daerah adalah <b>Perorangan</b> atau
                <b> Badan Usaha </b> yang memiliki kewajiban retribusi di daerah
                Provinsi Sumatera Utara.
            </em>
        </div>
        <div>
            <b-table-simple small bordered>
                <b-th colspan="6">
                    <h4>IDENTITAS WAJIB RETRIBUSI</h4>
                </b-th>
                <b-tr>
                    <b-td>
                        Nomor Registrasi
                    </b-td>
                    <b-td width="250px">
                        <div v-if="profil" class="font-weight-bold">
                            {{ profil.no_registrasi }}
                        </div>
                    </b-td>
                    <b-td>
                        Tgl. Registrasi
                    </b-td>
                    <b-td>
                        <div v-if="profil" class="font-weight-bold">
                            {{ profil.tgl_registrasi }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Nomor Induk
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil" class="font-weight-bold">
                            {{ profil.no_induk }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Nomor Pokok
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil" class="font-weight-bold">
                            {{ profil.no_pokok }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td width="200px">
                        Jenis
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            <span v-if="profil.jenis > 41">
                                Perorangan
                            </span>
                            <span v-else>
                                Badan Usaha
                            </span>
                        </div>
                    </b-td>
                </b-tr>
                <b-tr v-if="profil.bentuk_badan">
                    <b-td>
                        Bentuk Badan
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.bentuk_badan.nama }} ({{
                                profil.bentuk_badan.kode
                            }})
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Nama
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.nama }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Status Registrasi
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            <div
                                class="badge badge-success"
                                v-if="profil.status_registrasi_id === 5"
                            >
                                {{ profil.status_registrasi.nama }}
                            </div>
                            <div v-else class="badge badge-warning">
                                {{ profil.status_registrasi.nama }}
                            </div>
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td colspan="6"></b-td>
                </b-tr>
                <b-th colspan="6">
                    <h4>ALAMAT & KONTAK</h4>
                </b-th>
                <b-tr>
                    <b-td>
                        Nomor Handphone
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.nomp }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Nomor Telepon
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.notelepon }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Email
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.email }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Alamat
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.alamat }}
                            <div v-if="profil.wilayah_kelurahan">
                                <div>
                                    Kel. {{ profil.wilayah_kelurahan.nama }}
                                </div>
                                <div>
                                    Kec.
                                    {{
                                        profil.wilayah_kelurahan
                                            .wilayah_kecamatan.nama
                                    }}
                                </div>
                                <div>
                                    {{
                                        profil.wilayah_kelurahan
                                            .wilayah_kecamatan.wilayah_kabkota
                                            .nama
                                    }}
                                </div>
                                <div>
                                    {{
                                        profil.wilayah_kelurahan
                                            .wilayah_kecamatan.wilayah_kabkota
                                            .wilayah_provinsi.nama
                                    }}
                                </div>
                                <div>
                                    {{ profil.kodepos }}
                                </div>
                            </div>
                        </div>
                    </b-td>
                </b-tr>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import axios from "axios";

export default {
    data() {
        return {
            profil: "",
            user: JSON.parse(localStorage.getItem("user")),
        };
    },
    mounted() {
        if (this.user.npwrd_id) {
            this.getNpwrd(this.user.npwrd_id);
        }
    },
    methods: {
        getNpwrd(id) {
            axios
                .get("/api/wajib-retribusi/npwrd/" + id)
                .then(response => {
                    this.profil = response.data.data;
                    // disable tab jika kondis jenis
                    if (this.profil.jenis > 41) {
                        this.$store.commit("StepWajib/inputPerorangan", true);
                    } else if (this.profil.jenis < 41) {
                        this.$store.commit("StepWajib/inputPerorangan", false);
                    }

                    // input id pimpinan dan penanggung jawab ke state 
                    const wakil = {
                            pimpinan: '',
                            penanggung_jawab: ''
                        }
                    if (this.profil.penanggung_jawab) {
                        wakil.penanggung_jawab = this.profil.penanggung_jawab.id
                        localStorage.setItem('wakil', JSON.stringify(wakil))
                    } else {
                        localStorage.setItem('wakil', JSON.stringify(wakil))
                    }
                    if (this.profil.pimpinan) {
                        wakil.pimpinan = this.profil.pimpinan.id
                        localStorage.setItem('wakil', JSON.stringify(wakil))
                    } else {
                        localStorage.setItem('wakil', JSON.stringify(wakil))
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>

<template>
    <div>
        <HRetribusi imageLogo="imageLogo" />
        <!-- begin nav-tabs -->
        <b-tabs
            nav-class="nav-tabs-inverse bg-success"
            nav-wrapper-class="nav nav-tabs nav-tabs-inverse bg-success"
            v-if="user.npwrd_id"
        >
            <!-- begin tab -->
            <b-tab active>
                <template slot="title">
                    <span class="d-sm-none">Tab 1</span>
                    <span class="d-sm-block d-none">Profil Wajib Retribusi</span>
                </template>
                <Profil />
            </b-tab>
            <!-- end tab -->
            <!-- begin tab -->
            <b-tab :disabled="StepWajib.perorangan">
                <template slot="title">
                    <span class="d-sm-none">Tab 2</span>
                    <span :class="{'d-sm-block': true, 'd-none': true, 'line-through': StepWajib.perorangan}">Pimpinan</span>
                </template>
                <Pimpinan />
            </b-tab>
            <!-- end tab -->
            <!-- begin tab -->
            <b-tab :disabled="StepWajib.perorangan">
                <template slot="title">
                    <span class="d-sm-none">Tab 3</span>
                    <span :class="{'d-sm-block': true, 'd-none': true, 'line-through': StepWajib.perorangan}">Penanggung Jawab</span>
                </template>
                <PenanggungJawab />
            </b-tab>
            <!-- end tab -->
        </b-tabs>
        <!-- end nav-tabs -->
        <div v-else>
            <b-alert variant="warning" show>
                <h2 class="text-center">
                    Anda Belum Registrasi Data Wajib Retribusi
                </h2>
                <p class="text-center">
                    Klik di sini untuk
                    <router-link to="/dashboard" class="font-weight-bold"
                        >Registrasi</router-link
                    >
                </p>
            </b-alert>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import Logo from "@/assets/images/logoSumut.png";
import PageOptions from "@/config/PageOptions.vue";
import HRetribusi from "@/components/header/HRetribusi.vue";
import Profil from './Profil'
import Pimpinan from './Pimpinan'
import PenanggungJawab from './PenanggungJawab'
import { mapState } from 'vuex';

export default {
    data() {
        return {
            imageLogo: Logo,
            role: localStorage.getItem("roles"),
            user: JSON.parse(localStorage.getItem("user")),
            profil: null
        };
    },
    components: {
        HRetribusi,
        Profil,
        Pimpinan,
        PenanggungJawab
    },
    computed: {
        ...mapState([
            "StepWajib"
        ])
    },
    created() {
        PageOptions.pageEmpty = false;
        document.body.className = "";
    },
    mounted() {
    },
    methods: {
    }
};
</script>

<style scoped>
.peringatan {
    animation-name: example;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes example {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.07);
    }
}

.float-right:hover .cust-shadow {
    box-shadow: 3px 3px 7px rgba(83, 82, 82, 0.897);
    transition: all 0.3s linear;
}
.line-through {
    text-decoration: line-through;
}
</style>

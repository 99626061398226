<template>
    <div>
        <div class="mb-4">
            <h4 class="font-weight-bold">PIMPINAN</h4>
            <em>
                <b>Pimpinan Badan Usaha</b> adalah Pimpinan tertinggi dari Badan
                Usaha berupa Direktur, Direktur Utama, Ketua dan lain-lain.
            </em>
        </div>
        <div>
            <b-table-simple small bordered>
                <b-th colspan="6">
                    <h4>IDENTITAS PIMPINAN</h4>
                </b-th>
                <b-tr>
                    <b-td width="250px">
                        Nomor Induk
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil" class="font-weight-bold">
                            {{ profil.nik }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Nama
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.nama }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Jabatan
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.jabatan }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Nomor SK
                    </b-td>
                    <b-td>
                        <div v-if="profil">
                            {{ profil.no_sk }}
                        </div>
                    </b-td>
                    <b-td>
                        Tgl. SK
                    </b-td>
                    <b-td>
                        <div v-if="profil">
                            {{ profil.tgl_sk }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Status SK
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            <div v-if="profil.aktif === 1" class="badge badge-success">
                                Aktif
                            </div>
                            <div v-else class="badge badge-danger">
                                Non Aktif
                            </div>
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td colspan="6"></b-td>
                </b-tr>
                <b-th colspan="6">
                    <h4>ALAMAT & KONTAK</h4>
                </b-th>
                <b-tr>
                    <b-td>
                        Nomor Handphone
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.nomp }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Nomor Telepon
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.notelepon }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Email
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.email }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Alamat
                    </b-td>
                    <b-td colspan="3">
                        <div v-if="profil">
                            {{ profil.alamat }}
                            <div v-if="profil.wilayah_kelurahan">
                                <div>
                                    Kel. {{ profil.wilayah_kelurahan.nama }}
                                </div>
                                <div>
                                    Kec.
                                    {{
                                        profil.wilayah_kelurahan
                                            .wilayah_kecamatan.nama
                                    }}
                                </div>
                                <div>
                                    {{
                                        profil.wilayah_kelurahan
                                            .wilayah_kecamatan.wilayah_kabkota
                                            .nama
                                    }}
                                </div>
                                <div>
                                    {{
                                        profil.wilayah_kelurahan
                                            .wilayah_kecamatan.wilayah_kabkota
                                            .wilayah_provinsi.nama
                                    }}
                                </div>
                                <div>
                                    {{ profil.kodepos }}
                                </div>
                            </div>
                        </div>
                    </b-td>
                </b-tr>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import { mapState } from 'vuex';
import axios from 'axios'

export default {
    data() {
        return {
            profil: "",
            pimpinanId: JSON.parse(localStorage.getItem('wakil')).pimpinan
        };
    },
    mounted() {
        if (this.pimpinanId) {
            this.getPimpinan(this.pimpinanId)
        }
    },
    methods: {
        getPimpinan (id) {
            axios.get("/api/wajib-retribusi/npwrd-wakil/" + id).then(response => {
                this.profil = response.data.data
            }).catch(error => {
                console.log(error)
            })
        }
    }
};
</script>
